<template>
    <div class="page-content">
        <div class="page-list" v-for="item in list">
            <div class="page-one">
                <div class="page-video">
<!--                    <video :poster="item.fileUrl+'?x-oss-process=video/snapshot,t_50,f_jpg,w_400,h_350'"  controls="controls" :src="item.fileUrl" :alt="item.fileName"/>-->
                    <video :src="item" :poster="item + '?x-oss-process=video/snapshot,t_50,f_jpg,w_400,h_350'" controls="controls" width="100%" :alt="item"/>
                </div>
                <div class="page-name">
                    <div>{{item}}</div>
                    <div class="page-copy">
                        <van-button class="" type="info" @click="copyTextToClipboard(item)">复制链接</van-button>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="line">-->
<!--            <van-field v-model="videoUrl" type="text" placeholder="请输入链接" class="line-input" :clearable="true"/>-->
<!--        </div>-->
<!--        <div class="line">-->
<!--            <van-button type="info" @click="handleVideo" class="line-button" :loading="loadingShow">生成</van-button>-->
<!--        </div>-->
<!--        <div class="line" v-if="!!fileAddress">-->
<!--            <a :href="fileAddress" target="_blank">下载</a>-->
<!--        </div>-->
<!--        <div class="line" v-if="!!fileAddress">-->
<!--            {{fileAddress}}-->
<!--        </div>-->
    </div>
</template>
<script>
import axios from 'axios';

export default {
    name: 'home',
    data() {
        return {
            list: [],
            videoUrl: '',
            loadingShow: false,
            fileAddress: '',
            // fileAddress: 'https://13490f.spjgzs.com:13490/oss/yt-dlp/uxeanpjc8.mp4',
            price: '',
            code: '',
            openid: '',
            device_type: '',
            user_id: '',
            alipay_code: '',
            trade_no: ''
        }
    },
    components: {
    },
    created() {
        this.getList()
    },
    mounted() {
        document.title = '视频列表'
    },
    methods: {
        async handleVideo() {
            let params = {
                videoUrl: this.videoUrl
            }
            if (!params.videoUrl) {
                this.$toast('请输入链接')
                return false
            }
            this.loadingShow = true
            this.$util.ajax('/nas/ytDlp/getData', 'POST', params).then(res => {
                console.log(res.data.fileAddress)

                setTimeout(() => {
                    this.fileAddress = res.data.fileAddress
                    this.loadingShow = false

                }, 5000)
                // const url = window.URL.createObjectURL(blob);
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = url;
                // a.download = 'video.mp4'; // 下载的文件名
                // document.body.appendChild(a);
                // a.click();
                // window.URL.revokeObjectURL(url);
                //
                // alert('视频已下载。请在下载完成后保存到相册。');
                //
                // // 选项：在页面上显示视频
                // const videoPlayer = document.getElementById('videoPlayer');
                // videoPlayer.src = url;
                // videoPlayer.style.display = 'block';
                // if (!!res.data.user_id) {
                //     localStorage.user_id = res.data.user_id
                //     this.user_id = res.data.user_id
                // }
            })
        },
        async copyTextToClipboard(text) {
            try {
                await navigator.clipboard.writeText(text);
                console.log('Text copied to clipboard');
                this.$toast('链接已复制到剪贴板')

            } catch (err) {
                console.error('Failed to copy: ', err);
                this.$toast('复制失败')

            }
        },
        async getList() {
            let res = await this.$util.ajax('/nas/ytDlp/getList', 'POST' ,{})
            this.list = res.data.list

        },

    }
}
</script>

<style scoped lang="less">
.page-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0.6rem 0.3rem;
    background: #f0f0f0;
    .page-list {
        .page-one {
            background: #fff;
            display: flex;
            align-items: center;
            margin-bottom: 0.2rem;
            .page-name {
                font-size: 0.24rem;
            }
            .page-copy {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.4rem;
            }
        }
    }
    //.line {
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    padding-top: 0.6rem;
    //    .line-input {
    //        border-bottom: 1px solid #f0f0f0;
    //        font-size: 0.24rem;
    //        color: #000;
    //    }
    //    .line-button {
    //        width: 100%;
    //    }
    //}
}
</style>
